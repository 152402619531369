import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './Header.css'
import * as scatter from '../../img/scatter.png'

class Header extends Component {
  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired
      }).isRequired,
      staticContext: PropTypes.object
    }).isRequired
  }

  render() {
    return <div className="Header">
      <div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 bg-white border-bottom shadow-sm">
      <h5 className="my-0 mr-md-auto font-weight-normal"><a href="/"><img src="/logo-text-tlos.png" alt="Telos Gift Card" style={{height:58, paddingLeft:30, paddingRight:30, margin:'-10px 0'}}/></a></h5>
      <nav className="my-2 my-md-0 mr-md-3">
        <span>{this.props.account? <span><img src={scatter} alt="Scatter" className="rounded-circle" style={{height:38}}/> {this.props.account.name} &nbsp; </span> : <span></span>}</span>
        <a className="p-2 text-dark" href="#about">About</a>
        <span> &nbsp; </span>
        <a className="p-2 text-dark" href="#pricing">Pricing</a>
      </nav>
      {
        this.props.account
        ?
        <button onClick={this.props.logout} className="btn btn-outline-primary">Logout</button>
        :
        <button onClick={this.props.scatterConnect} className="btn btn-outline-primary">Scatter Login</button>
      }
    </div>
    </div>
  }
}

export default Header
