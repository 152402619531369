import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './Footer.css'
import * as eosLogo from '../../img/eosLight.gif'

class Footer extends Component {
  static propTypes = {
    children: PropTypes.any.isRequired
  }

  render() {
    return (
      <div className="Footer">
        <div className="content">
          {this.props.children}
        </div>
        <section className="dark">
        <div className="container">
        <footer>
  <div className="row">
    <div className="col-12 col-md">
      <img className="mb-2" src={eosLogo} alt="EOS" width="24" height="24"/>
      <small className="d-block mb-3 text-muted">made with ❤ in Europe<br/><br/>Imprint: L. Märdian<br/>Fritz-Bender-Str. 6<br/>67480 Edenkoben</small>
    </div>
    <div className="col-6 col-md">
      <h5>Open Source</h5>
      <span className="text-muted">This service uses an open source <a href="https://telos.eosx.io/account/cointreasury" target="_blank" rel="noopener noreferrer">smart contract</a>, which can be reviewed in our Github repository.</span>
    </div>
    <div className="col-6 col-md">
      <h5>Contact</h5>
      <span className="text-muted">If you have any suggestions, please get in touch with us:<br/><a href="mailto:hello[at]telosgiftcard.com">hello@telosgiftcard.com</a></span>
    </div>
    <div className="col-6 col-md">
      <h5>Community</h5>
      <span className="text-muted">
      <a href="https://eosgiftcard.com">EOS Gift Card</a>
      <br/>
      <a href="https://telosgiftcard.com">Telos Gift Card</a>
      <br/>
      Github: <a href="https://github.com/slyon/signup" target="_blank" rel="noopener noreferrer">@slyon</a>
      <br/>
      Twitter: <a href="https://twitter.com/eosgiftcard" target="_blank" rel="noopener noreferrer">@eosgiftcard</a>
      </span>
    </div>
  </div>
</footer>

      </div></section></div>
    )
  }
}

export default Footer
