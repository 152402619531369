import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch, Link } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import Home from './components/Home'
import Test from './components/Test'
import ScatterJS from 'scatterjs-core'
import ScatterEOS from 'scatterjs-plugin-eosjs'
import Eos from 'eosjs'

ScatterJS.plugins( new ScatterEOS() )


// Networks are used to reference certain blockchains.
// They let you get accounts and help you build signature providers.
const network = {
    blockchain:'eos',
    protocol:'https',
    host: process.env.REACT_APP_EOS_HOST || 'public.eosinfra.io',
    port:443,
    chainId: process.env.REACT_APP_CHAIN_ID || 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906'
}

class App extends Component {
  constructor () {
    super()
    this.state = {
      scatter: null,
      account: null,
      eos: null
    }
  }

  async scatterConnect() {
    try {
      const connected = await ScatterJS.scatter.connect("telos.eosgiftcard.com")
      if(!connected) {
        if(window.confirm('Scatter not found.\nDownload now at get-scatter.com?')) {
          window.open('https://get-scatter.com', '_blank')
        }
        return false
      }
      const scatter = ScatterJS.scatter
      window.scatter = null
      // Now we need to get an identity from the user.
      // We're also going to require an account that is connected to the network we're using.
      await scatter.getIdentity({ accounts: [network] })
      // Always use the accounts you got back from Scatter. Never hardcode them even if you are prompting
      // the user for their account name beforehand. They could still give you a different account.
      const account = scatter.identity.accounts.find(x => x.blockchain === 'eos')
      // You can pass in any additional options you want into the eosjs reference.
      // Get a proxy reference to eosjs which you can use to sign transactions with a user's Scatter.
      const eos = scatter.eos(network, Eos, { expireInSeconds: 60 })
      this.setState({ scatter, account, eos })
    } catch(err) {
      console.error('scatterConnect', err)
      return false
    }
  }

  async logout() {
    const scatter = this.state.scatter
    scatter.forgetIdentity()
    this.setState({ scatter: null, account: null, eos: null })
  }

  async componentWillMount() {
    //await this.scatterConnect()
  }

  render() {
    const errorPage = <div className="text-center">
      <br/><br/><br/><br/>
      <h1>404</h1>
      <h3>Page not found.</h3>
      <br/>
      <Link to="/">zurück</Link>
    </div>

    return (
      <Router>
        <Footer>
          <Header {...this.state} logout={this.logout.bind(this)} scatterConnect={this.scatterConnect.bind(this)} />
          <Switch>
            <Redirect from="/index.html" to="/" />
            <Route exact path="/" component={() => <Home {...this.state} scatterConnect={this.scatterConnect.bind(this)}/>}/>
            <Route exact path="/testing" component={() => <Test/>}/>
            <Route component={() => errorPage}/>
          </Switch>
        </Footer>
      </Router>
    )
  }
}

export default App
